<template>
  <div>
    <h3>Your order is paid for, now we just need a few more details.</h3>
    <p>To help establish your company registration, we need to gather a few more pieces of information about the business registration you need completed.</p>
    <p>Thanks for using our services!</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'StagelinePostPayment',

  props: {
  },

  computed: {
  },

  async mounted() {
    await this.loadIfNotAvailableById( {
      ids: this.invoiceId,
      params: { include_details: true },
    })
  },

  methods: {
    ...mapActions('invoices', ['loadIfNotAvailableById']),
  },
}
</script>

<style scoped>
</style>
